
export const validateExtendedAlphaString = (data) => {
    let str = data.toString();
    return str.match(/^[a-zA-ZÀ-ÿ_\-/\s´`.',]+$/) !== null;
}

export const validateExtendedAlphaNumericalString = (data) => {
    let str = data.toString();
    return str.match(/^[a-zA-ZÀ-ÿ_\-/\s´`'\d.',]+$/) !== null;
}

export const validateDate = (data) => {
    let str = data.toString();
    let valid = false
    valid |= str.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
    valid |= str.match(/^\d{2}\/\d{2}\/\d{4}$/) !== null;
    valid |= str.match(/^\d{2}.\d{2}.\d{4}$/) !== null;
    return valid;
}

export const validateNumber = (data) => {
    return data.toString().match(/^\d+$/) !== null;
}

export const validateYear = (data, allow_future = false) => {
    return validateNumber(data) && parseInt(data) >= 1900 && (allow_future ? true : parseInt(data) <= (new Date()).getFullYear());
}

export const validateFloat = (data) => {
    return data.toString().match(/^\d+(\.\d+)?$/) !== null;
}

export const validateEmail = (str) => {
    return str.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) !== null;
}   
