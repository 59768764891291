import { useState, useMemo, useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import { ReactSelectBootstrap } from 'react-select-bootstrap';

import Store from '@sharedModel/Store';
import { validateExtendedAlphaString, validateNumber, validateExtendedAlphaNumericalString, validateYear } from '@sharedMiscellaneous/validationFunctions';
import { MonthYear } from '@sharedModel/Types';
import * as Model from '@sharedModel/Types'


const MonthPicker = (props) => {

    var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    return (
        <DropdownButton variant="secondary" onSelect={props.onSelect} title={months[props.month]} className={props.className}>
            {
                months.map((name, index) => {
                    return (
                        <Dropdown.Item eventKey={index} key={index}>{name}</Dropdown.Item>
                    );
                })
            }
        </DropdownButton>
    );
}

const handleOptionalValidation = (str) => {
    if (str.length > 0) {
        return !validateExtendedAlphaString(str);
    } else {
        return false;
    } 
}

const handleCountryValidation = (str) => {
    // eslint-disable-next-line
    if (str !== "") {
        return true;
    } else {
        return false;
    } 
}

const validateStart = (start) => {
    let valid = true;
    valid &= validateYear(start.year);
    valid &= start.month >= 0 && start.month <= 11;
    return valid;
}
const validateEnd = (start, end, allow_future) => {
    let valid = true;
    valid &= validateYear(end.year, allow_future);
    valid &= end.month >= 0 && end.month <= 11;
    valid &= end.year >= start.year;
    valid &= (end.year <= start.year) ? end.month > start.month : true;
    return valid;
}

// component to edit the degree information - mainly the modal
const DegreeDetailsModal = (props) => {
    // local state variables for all the degree information
    // these are written to the global state store when clicking save button

    const [country, setCountry] = useState(props.degree.granting_institution.country);
    const [institution, setInstitution] = useState(props.degree.granting_institution.name);
    const [college, setCollege] = useState(props.degree.granting_institution.college);
    const [degree, setDegree] = useState(props.degree.program.degree);
    const [subject, setSubject] = useState(props.degree.program.subject);
    const [specialization, setSpecialization] = useState(props.degree.program.specialization);
    const [duration_nominal, setDurationNominal] = useState(props.degree.program.duration_nominal);
    const [credits_nominal, setCreditsNominal] = useState(props.degree.program.credits_nominal);
    const [credit_system, setCreditSystem] = useState(props.degree.program.credit_system);
    const [date_start, setStart] = useState(props.degree.report.date_start);
    const [date_end, setEnd] = useState(props.degree.report.date_end);
    const [completed, setCompleted] = useState(props.degree.report.completed);
    const [credits, setCredits] = useState(props.degree.report.credits);

    const [countryValid, setCountryValid] = useState(handleCountryValidation(country));

    const countryOptions = useMemo(() => countryList().getData(), [])


    const getCountryFromObject = (countryObject) => {
        if (countryObject === null) return "";
        if (countryObject.hasOwnProperty("label")) return countryObject.label;
        return ""
    }

    // helper function evaluate all validation functions
    // disabling eslint, we would either way need to hook to all the state variables
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isValid = () => {

        let valid = true;
        valid &= validateExtendedAlphaString(getCountryFromObject(country));
        valid &= validateExtendedAlphaString(institution);
        valid &= validateExtendedAlphaString(college) || college.length <= 0;
        valid &= validateExtendedAlphaString(degree);
        valid &= validateExtendedAlphaNumericalString(subject);
        valid &= validateExtendedAlphaNumericalString(specialization) || specialization.length <= 0;
        valid &= validateNumber(duration_nominal) && parseFloat(duration_nominal) > 0;
        valid &= validateNumber(credits_nominal) && parseFloat(credits_nominal) > 0;
        valid &= validateExtendedAlphaNumericalString(credit_system);
        valid &= validateStart(date_start);
        valid &= validateEnd(date_start, date_end, !completed);
        return valid

    }

    // handle the save button
    // disabling eslint, we would either way need to hook to all the state variables and props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSave = () => {

        Store.getState().updateDegree(
            new Model.Degree(
                new Model.DegreeGrantingInstitution(
                    country,
                    institution,
                    college
                ),
                new Model.DegreeProgram(
                    degree,
                    subject,
                    specialization,
                    parseFloat(duration_nominal),
                    parseFloat(credits_nominal),
                    credit_system
                ),
                new Model.DegreeFinalReport(
                    date_start,
                    date_end,
                    completed,
                    parseFloat(credits)
                ),
                props.degree.id
            )
        );

        props.handleClose();
    }

    useEffect(() => {

        if (props.show) {
            setCountry(props.degree.granting_institution.country);
            setInstitution(props.degree.granting_institution.name);
            setCollege(props.degree.granting_institution.college);
            setDegree(props.degree.program.degree);
            setSubject(props.degree.program.subject);
            setSpecialization(props.degree.program.specialization);
            setDurationNominal(props.degree.program.duration_nominal);
            setCreditsNominal(props.degree.program.credits_nominal);
            setCreditSystem(props.degree.program.credit_system);
            setStart(props.degree.report.date_start);
            setEnd(props.degree.report.date_end);
            setCompleted(props.degree.report.completed);
            setCredits(props.degree.report.credits);
        }

    }, [props.degree, props.show]);

    const modalTitle = (createNew) => {
        if (createNew) return "Create new";
        else return "Edit";
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (props.show && event.key === 'Enter') {
                if (isValid()) {
                    handleSave();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, isValid]);

    useEffect(() => {
        const matchRowSize = (source, target) => {
            if(target.style !== undefined){
                if(window.innerWidth >= 576){
                    target.style.height = `${source.clientHeight}px`;
                }
                else{
                    target.style.height = "auto";
                }
            }
        };
    
        const firstRowObserver = new ResizeObserver((source, target) => {
            matchRowSize(source[0].target, targetRow);
        });
        const firstRowObserverNC = new ResizeObserver((source, target) => {
            matchRowSize(source[0].target, targetRowNC);
        });
    
        const sourceRow = document.querySelector(".observer-height-source");
        const targetRow = document.querySelector(".observer-height-target");

        const sourceRowNC = document.querySelector(".observer-height-source-nc");
        const targetRowNC = document.querySelector(".observer-height-target-nc");

        if(sourceRow != null && targetRow != null){
            firstRowObserver.observe(sourceRow, targetRow);
            matchRowSize(sourceRow, targetRow);
        }
        if(sourceRowNC != null && targetRowNC != null){
            firstRowObserverNC.observe(sourceRowNC, targetRowNC);
            matchRowSize(sourceRowNC, targetRowNC);
        }
    }, [props.show]);

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} className="degree-modal">

                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle(props.createNew)} degree</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="modal-form">
                        <div>
                            Please fill in the information of your institution degree as on your transcript of records.
                        </div>

                        <Form.Group>
                            <Form.Label className="mandatory">Institution's name</Form.Label>
                            <Form.Control type="text"
                                required
                                value={institution}
                                placeholder="University of ..."
                                onChange={(e) => { setInstitution(e.target.value) }}
                                isInvalid={!validateExtendedAlphaString(institution)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>College's name (optional)</Form.Label>
                            <Form.Control type="text"
                                value={college}
                                placeholder="A possible college belonging to your institution"
                                onChange={(e) => { setCollege(e.target.value) }}
                                isInvalid={handleOptionalValidation(college)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="mandatory">Country of your institution</Form.Label>
                            <ReactSelectBootstrap
                                className="react-select"
                                isClearable
                                placeholder="Select your institution's country ..."
                                options={countryOptions}
                                value={country}
                                onChange={(e) => {
                                    setCountry(e);
                                    if(e != null) setCountryValid(handleCountryValidation(e.value));
                                    else setCountryValid(false);
                                }}
                                styles={{
                                    /* make this react-select behave like an original dropdown */
                                    clearIndicator: (provided, state) => ({
                                        ...provided,
                                        fill: "white"
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.menuIsOpen ? "color-mix(in srgb, var(--bs-secondary) 80%, black)" : "var(--bs-secondary)",
                                        color: state.menuIsOpen ? "var(--bs-secondary-color)" : "var(--bs-dropdown-link-color)",
                                        border: (countryValid ? state.menuIsOpen ? "1px solid color-mix(in srgb, var(--bs-secondary) 90%, black) !important" : "1px solid var(--bs-secondary) !important" : "1px solid var(--bs-form-invalid-border-color) !important")
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        fill: "white"
                                    }),
                                    indicatorSeparator: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: "white"
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: "white"
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: "var(--bs-body-bg)",
                                        border: "1px solid var(--bs-border-color-translucent)",
                                        borderRadius: "var(--bs-border-radius)",
                                        boxShadow: "none",
                                        overflow: "hidden",
                                        marginTop: "2px",
                                        marginBottom: "2px",
                                        zIndex: "1000"
                                    }),
                                    menuList: (provided, state) => ({
                                        ...provided,
                                        padding: "var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x)"
                                    }),
                                    noOptionsMessage: (provided, state) => ({
                                        ...provided,
                                        color: "var(--bs-dropdown-link-color)"
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected ? "var(--bs-secondary-bg) !important" : state.isFocused ? "var(--bs-tertiary-bg)" : "var(--bs-body-bg)",
                                        color: "var(--bs-dropdown-link-color)",
                                        "&:active": {
                                            backgroundColor: "var(--bs-primary)",
                                            color: "white"
                                        }
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: "white"
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: "white"
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        color: "inherit"
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="mandatory">Degree</Form.Label>
                            <Form.Control type="text"
                                required
                                value={degree}
                                placeholder="e.g. B.Sc., B.Eng., B.Tech."
                                onChange={(e) => { setDegree(e.target.value) }}
                                isInvalid={!validateExtendedAlphaString(degree)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="mandatory">Study program's name</Form.Label>
                            <Form.Control type="text"
                                required
                                value={subject}
                                placeholder="Mechatronics"
                                onChange={(e) => { setSubject(e.target.value) }}
                                isInvalid={!validateExtendedAlphaNumericalString(subject)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Specialization (optional)</Form.Label>
                            <Form.Control type="text"
                                required
                                value={specialization}
                                placeholder="Embedded Systems"
                                onChange={(e) => { setSpecialization(e.target.value) }}
                                isInvalid={handleOptionalValidation(specialization)}
                            />
                        </Form.Group>

                        <Row xs={1} sm={2}>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="observer-height-source mandatory">Nominal duration (terms, e.g. semesters, no summer school)</Form.Label>
                                    <Form.Control type="number"
                                        required
                                        value={duration_nominal}
                                        onChange={(e) => { setDurationNominal(e.target.value) }}
                                        isInvalid={!validateNumber(duration_nominal) || duration_nominal <= 0}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="observer-height-source-nc mandatory">Nominal credits (minimum required credits to finish your program)</Form.Label>
                                    <Form.Control type="number"
                                        required
                                        value={credits_nominal}
                                        onChange={(e) => { setCreditsNominal(e.target.value) }}
                                        isInvalid={!validateNumber(credits_nominal) || credits_nominal <= 0}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="mandatory">Credit System</Form.Label>
                                    <Form.Control type="text"
                                        required
                                        value={credit_system}
                                        placeholder="e.g. ECTS, CP, hours"
                                        onChange={(e) => { setCreditSystem(e.target.value) }}
                                        isInvalid={!validateExtendedAlphaNumericalString(credit_system)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="observer-height-target">Your credits (optional)</Form.Label>
                                    <Form.Control type="number"
                                        required
                                        value={credits}
                                        onChange={(e) => { setCredits(e.target.value) }}
                                        isInvalid={!validateNumber(credits)}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="observer-height-target-nc">Begin of your degree</Form.Label>
                                    <Row>
                                        <Col xs={6} sm={7} md={6}>
                                            <MonthPicker month={date_start.month} onSelect={ (month) => setStart(new MonthYear(month, date_start.year)) } className="month-picker" />
                                        </Col>
                                        <Col xs={6} sm={5} md={6}>
                                            <Form.Control type="number" 
                                                required 
                                                value={date_start.year}
                                                onChange={ (e) => { setStart(new MonthYear(date_start.month, e.target.value)) }} 
                                                isInvalid={ !validateStart(date_start) }
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>End of your degree</Form.Label>
                                    <Row>
                                        <Col xs={6} sm={7} md={6}>
                                            <MonthPicker month={date_end.month} onSelect={ (month) => setEnd(new MonthYear(month, date_end.year)) } className="month-picker w-100" />
                                        </Col>
                                        <Col xs={6} sm={5} md={6}>
                                            <Form.Control type="number" 
                                                required 
                                                value={date_end.year}
                                                onChange={ (e) => { setEnd(new MonthYear(date_end.month, e.target.value)) }} 
                                                isInvalid={ !validateEnd(date_start, date_end, !completed) }
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Form.Group>
                                <Form.Label>Completed</Form.Label>
                                <Form.Check type="switch"
                                    required
                                    checked={completed}
                                    label="Studies completed"
                                    onChange={() => { setCompleted(!completed) }}
                                />
                            </Form.Group>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSave} disabled={!isValid()}>Save Changes</Button>
                </Modal.Footer>

            </Modal>
        </>
    );

}

export default DegreeDetailsModal;