import { ApplicationProgram } from '@sharedModel/Types';

// Import of application programs
import Mechatronics from '@sharedApplicationPrograms/Mechatronics';
import MechanicalEngineeringAndManagement from '@sharedApplicationPrograms/MechanicalEngineeringAndManagement';
import EnvironmentalEngineering from '@sharedApplicationPrograms/EnvironmentalEngineering';
import DataScience from '@sharedApplicationPrograms/DataScience';
import MicroelectronicsAndMicrosystems from '@sharedApplicationPrograms/MicroelectronicsAndMicrosystems';


export class ApplicationPrograms {

    // Loaded application programs
    static MEC = Object.assign(new ApplicationProgram(), Mechatronics);
    static MEM = Object.assign(new ApplicationProgram(), MechanicalEngineeringAndManagement);
    //i didnt got the Data for EE jet
    static EE = Object.assign(new ApplicationProgram(), EnvironmentalEngineering); 
    // i got them by Email from  anna.kirf@tuhh.de
    static DS = Object.assign(new ApplicationProgram(), DataScience);  
    // i got them by Email from  anna.kirf@tuhh.de
    static MM = Object.assign(new ApplicationProgram(), MicroelectronicsAndMicrosystems);  
     

    // List of all application programs
    static list = [
        this.MEC,
        this.MEM,
        this.DS,
        this.MM,
        this.EE
    ];


    // Static Methods

    static standard = () => { return this.MEC; }

    // checks if the given programId is valid
    static isValidId = (programId) => {
        return this.list.find((ele) => ele.id === programId) !== undefined;
    }

    // returns the program object with the given programId
    static getProgramById = (programId) => {
        return this.list.find((ele) => ele.id === programId);
    }

    // returns the programId if valid, otherwise the standard programId
    static getValidProgramId = (programId) => {
        return this.isValidId(programId) ? programId : this.standard().id;
    }

    // returns the program object with the given programId if valid, otherwise the standard program object
    static getValidProgramById = (programId) => {
        return this.getProgramById(this.getValidProgramId(programId));
    }

}