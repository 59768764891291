import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist, createJSONStorage } from 'zustand/middleware';


import * as Model from '@sharedModel/Types';
import { ApplicationPrograms } from '@sharedApplicationPrograms/ApplicationPrograms';

const MAX_STORE_AGE = 1000 * 60 * 60 * 24 * 300; // 300 days

const Store = create(
	persist(immer((set, get) =>

		Object.assign({}, new Model.Application(), {

			// date: Date.now(),

			//////////////////////////////////////////////////////////////////////////////
			// Getters and setters
			
			setComment: (comment, k = 0) => {
				set((state) => {
					state.comment[k] = comment;
				});
			},

			// set personal_information
			setPersonalInformationProperty: (property, value) => {
				set((state) => {
					return {
						...state,
						personal_information: {
							...state.personal_information,
							[property]: value
						}
					};
				});
			},

			//////////////////////////////////////////////////////////////////////////////
			// degree related functions

			// add a degree; create if none is given
			updateDegree: (degree = new Model.Degree()) => {
				let retval = degree.id;

				set((state) => {
					let idx = state.degrees.findIndex(
						(d) => d.id === degree.id
					);
					if (idx === -1) {
						state.degrees.push(degree);
					} else {
						state.degrees[idx] = degree;
					}

					return state;
				});

				return retval;
			},

			// remove a degree based on its uuid
			removeDegree: (ref, k = -1) => {
				let degreeId = ref;
				// let degreeId = ref instanceof Model.Degree ? ref.id : ref;
				let retval = degreeId;

				set((state) => {
					retval = degreeId;
					state.program_qualifications[k < 0 ? state.program_qualifications.length + k : k] = state.program_qualifications.at(k).filter((q) => { return q.degree !== degreeId; })
					state.courses = state.courses.filter((c) => c.degree !== degreeId)
					state.degrees = state.degrees.filter((d) => d.id !== degreeId)
				});

				// will always return id, if present in store before deletion or not
				return retval;
			},

			getDegreeById: (degreeId) => {
				return get().degrees.find((d) => d.id === degreeId);
			},


			//////////////////////////////////////////////////////////////////////////////
			// course related functions

			// add a course; create if none is given
			updateCourse: (course = new Model.Course()) => {
				let retval = course.id;

				set((state) => {
					let idx = state.courses.findIndex(
						(c) => c.id === course.id
					);
					if (idx === -1) {
						state.courses.push(course);
					} else {
						state.courses[idx] = course;
					}

					return state;
				});

				return retval;
			},

			getCourseById: (courseId) => {
				return get().courses.find((c) => c.id === courseId);
			},

			// remove a course based on its uuid
			// will also remove the course from the degree: degrees[degreeId].courses
			removeCourse: (ref, k = -1) => {
				let courseId = ref;
				// let courseId = ref instanceof Model.Course ? ref.id : ref;
				let retval = courseId;

				set((state) => {
					state.program_qualifications[k < 0 ? state.program_qualifications.length + k : k] = state.program_qualifications.at(k).filter((q) => q.course !== courseId);
					state.courses = state.courses.filter((c) => c.id !== courseId)
				});

				// will always return id, if present in store before deletion or not
				return retval;

			},

			//////////////////////////////////////////////////////////////////////////////
			// program_qualifications related functions

			// add a new matching between a course and a prerequisite
			// degreeId: id of degree to which the course belongs
			// courseId: id of course to match
			// targetId: id of target to match
			addMatching: (
				applicationProgramId,
				courseId,
				targetId,
				credits = 0,
				k = -1
			) => {
				let retval = null;

				set((state) => {
					let degreeId = state.courses.find((c) => c.id === courseId).degree;

					// handle double matches in same revision
					if (state.program_qualifications.at(k).findIndex(
						(q) => 	q.application_program === applicationProgramId &&
								q.degree === degreeId &&
								q.course === courseId && 
								q.target === targetId
					) >= 0) {
						return state;
					}

					// handle existing matches in other revisions/student
					for (let i = 0; i < state.program_qualifications.length - 1; i++) {
						// get the -(i + 2) match so starting with the last revision than (student)
						const existingMatch = state.program_qualifications.at(-(i + 2)).find(
							(q) => 	q.application_program === applicationProgramId &&
									q.degree === degreeId &&
									q.course === courseId && 
									q.target === targetId
						);
						if (existingMatch) {
							retval = existingMatch.id;
							state.program_qualifications.at(k).push(existingMatch);
							return state;
						}
					}
					
					// no existing match add new match
					const match = new Model.QualificationAllocator(
						applicationProgramId,
						degreeId,
						courseId,
						targetId,
						credits
					);
					retval = match.id;

					state.program_qualifications.at(k).push(match);
				});

				return retval;
			},

			// remove a matching based on its uuid (dictionary key) in programQualifications[matchingId]
			removeMatching: (matchingId, k = -1) => {
				// const matchingId = ref instanceof Model.QualificationAllocator ? ref.id : ref;
				
				set((state) => {
					state.program_qualifications[k < 0 ? state.program_qualifications.length + k : k] = state.program_qualifications.at(k).filter((q) => q.id !== matchingId)
				});

				return matchingId;
			},

			// remove all matchings for a course, so removes the match everywhere in the program_qualifications[k]
			removeMatchingByCourseId: (courseId, k = -1) => {
				const matchingIds = get().program_qualifications.at(k).filter(q => q.course === courseId).map(q => q.id);
				matchingIds.forEach(id => get().removeMatching(id, k));
				return matchingIds;
			},

			// remove all matchings in a prerequisite field
			removeMatchingByFieldIdAndCourseId: (courseId, applicationProgramId, fieldId, k = -1) => {
				const targetIds = ApplicationPrograms.getValidProgramById(applicationProgramId).fields.filter(f => f.id === fieldId)[0].courses.map(c => c.id);
				console.log(targetIds)
				const matchingIds = get().program_qualifications.at(k).filter(q => (targetIds.includes(q.target) && q.course === courseId)).map(q => q.id);
				console.log(matchingIds)
				matchingIds.forEach(id => get().removeMatching(id, k));
				return matchingIds;
			},

			getCreditsInRevision: (matchingId, k = -1) => {
				const matching = get().program_qualifications.at(k).find(q => q.id === matchingId);
				return matching ? matching.credits : 0;
			},

			// set matching credits
			setMatchingCredits: (matchingId, credits, k = -1) => {

				let retval = 0;

				set((state) => {
					const match = state.program_qualifications.at(k).find(q => q.id === matchingId);
					
					const courseCredits = state.courses.find(c => c.id === match.course).credits;
					const courseAllocatedCredits = state.getTotalAllocatedCreditsByCourseId(match.application_program, match.course);
					
					const currentCredits = match.credits;

					// * parse number
					// * clamp negative values to 0
					// * clamp to remaining credits from course
					let validCredits = Math.min(Math.max(0, parseFloat(credits) || 0), courseCredits - courseAllocatedCredits + currentCredits);
					retval = validCredits;
					
					state.program_qualifications[k < 0 ? state.program_qualifications.length + k : k] = state.program_qualifications.at(k).map((q) =>
						q.id === matchingId ? { ...q, credits: validCredits } : q
					)
				});

				return retval;
			},

			incrementMatchingCredits: (matchingId, step = 0.5, k = -1) => {
				const newCredits = get().program_qualifications.at(k).find(q => q.id === matchingId).credits + step;
				return get().setMatchingCredits(matchingId, newCredits);
			},

			decrementMatchingCredits: (matchingId, step = 0.5, k = -1) => {
				const newCredits = get().program_qualifications.at(k).find(q => q.id === matchingId).credits - step;
				return get().setMatchingCredits(matchingId, newCredits);
			},

			getTotalAllocatedCreditsByCourseId: (applicationProgramId, courseId, k = -1) => {
				return get().program_qualifications.at(k)
					.filter(q => q.course === courseId && q.application_program === applicationProgramId)
					.map(q => q.credits)
					.reduce((acc, val) => { return acc + val; }, 0);
			},

			getCreditsByMatchInRevision: (matchingId, k = -1) => {
				const match = get().program_qualifications.at(k).find(q => q.id === matchingId);
				return match ? match.credits : 0;
			},

			getAllocatedQualificationsByPrereqCourse: (applicationProgramId, prerequisiteCourseId, k = -1) => {
				return get().program_qualifications.at(k).filter(q => q.target === prerequisiteCourseId && q.application_program === applicationProgramId);
			},

			duplicateLastProgramQualifications: () => {
				set((state) => {
					const lastProgramQualifications = state.program_qualifications.at(-1);
					state.program_qualifications.push(lastProgramQualifications.map(item => ({...item})));
				});
			},

			//////////////////////////////////////////////////////////////////////////////
			// miscellaneous functions

			exportJSON: () => {
				return JSON.stringify(get());
			},

			resetStore: () => {
				set(() => {
					return new Model.Application();
				});
			},

			importStore: (store) => {
				
				//////////////////////////////////////////////////////////////////////////////
				// pre 0.2.3-rc.0
				
				// fixing missing version pre ad6b83339866b405d76ccb3c7ff3195ece0105df
				if (!store.hasOwnProperty('version')) {
					store.version = '0.1.1';

					// fixing name/surname mismatch pre bc757481f32eb9ddf3471d44c49b2c13057223d2
					if (store.personal_information.hasOwnProperty('name')) {
						store.personal_information.first_name = store.personal_information.name;
					}
					if (store.personal_information.hasOwnProperty('surname')) {
						store.personal_information.last_name = store.personal_information.surname;
					}
				}

				// adding array for additional comments
				if (!(store.comment instanceof Array)) {
					store.comment = [store.comment, ""];
				}

				// adding array for additional allocations (-> commission)
				if (store.program_qualifications instanceof Array && store.program_qualifications.length > 0) {
					// it is an array and with length > 0

					if (!(store.program_qualifications[0] instanceof Array)) {
						// the frist element is not an array -> migrating program_qualifications

						const program_qualifications = store.program_qualifications.map(item => ({...item}));
						store.program_qualifications = [program_qualifications];
					}

				} else {
					// no data at all
					store.program_qualifications = [[]]
				}

				//////////////////////////////////////////////////////////////////////////////
				// update version
				store.version = Model.version;

				set(() => {
					return store;
				});
			}

		})

	),
		{
			version: Model.version,
			name: 'tuhh-ects-2.0',
			storage: createJSONStorage(() => sessionStorage),
			migrate: (persistedState, version) => {
				
				// handle versions
				if (version !== Model.version) {
					console.log("persisted store version mismatch");
					
					return Store.importStore(persistedState);
				}
				
				return persistedState
			},
			merge: (persistedState, currentState) => {

				// store is to old
				if (Date.now() - persistedState.date > MAX_STORE_AGE) {
					console.log("persisted store is to old");
					return currentState;
				}
				
				// update date
				persistedState.date = Date.now();

				return {
					...currentState,
					...persistedState
				}
			}
		})
);

export default Store;
