import { useState, useEffect } from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import { ReactSelectBootstrap } from 'react-select-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';

import MatchAllocationCard from '@components/MatchAllocationCard';
import PrerequisiteCourse from '@components/PrerequisiteCourse';

import Store from '@sharedModel/Store';


// component to select a course from the student through dropdown
const MatchingDropdown = (props) => {

    const programQualifications = Store(state => state.program_qualifications[0]);

    // local state containing all courses of the student as a list
    const [options, setOptions] = useState([]);
    // all courses of the student
    const courses = Store(state => state.courses)

    // update local state when courses change
    // regenerating the options list
    useEffect(() => {

        let existingMatches = programQualifications
            .filter(q => q.target === props.prerequisite_course.id && q.application_program === props.application_program.id)
            .map((match) => match.course);

        let options = courses.reduce((result, course) => {
            if (!existingMatches.includes(course.id))
                result.push({ value: course.id, label: (course.course_code ?? "") + " " + course.subject });
            return result;
        }, []);

        setOptions(options);

    }, [courses, programQualifications, props.prerequisite_course, props.application_program]);

    return (
        <ReactSelectBootstrap className="react-select mt-3" options={options} isClearable placeholder="Add Module/Lecture"
            styles={{
                /* make this react-select behave like an original dropdown */
                clearIndicator: (provided, state) => ({
                    ...provided,
                    fill: "white"
                }),
                control: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.menuIsOpen ? "color-mix(in srgb, var(--bs-secondary) 80%, black)" : "var(--bs-secondary)",
                    color: state.menuIsOpen ? "var(--bs-secondary-color)" : "var(--bs-dropdown-link-color)",
                    border: (true ? state.menuIsOpen ? "1px solid color-mix(in srgb, var(--bs-secondary) 90%, black) !important" : "1px solid var(--bs-secondary) !important" : "1px solid var(--bs-form-invalid-border-color) !important")
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    fill: "white"
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    backgroundColor: "white"
                }),
                input: (provided, state) => ({
                    ...provided,
                    color: "white"
                }),
                menu: (provided, state) => ({
                    ...provided,
                    backgroundColor: "var(--bs-body-bg)",
                    border: "1px solid var(--bs-border-color-translucent)",
                    borderRadius: "var(--bs-border-radius)",
                    boxShadow: "none",
                    overflow: "hidden",
                    marginTop: "2px",
                    marginBottom: "2px",
                    zIndex: "1000"
                }),
                menuList: (provided, state) => ({
                    ...provided,
                    padding: "var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x)"
                }),
                noOptionsMessage: (provided, state) => ({
                    ...provided,
                    color: "var(--bs-dropdown-link-color)"
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "var(--bs-secondary-bg) !important" : state.isFocused ? "var(--bs-tertiary-bg)" : "var(--bs-body-bg)",
                    color: "var(--bs-dropdown-link-color)",
                    "&:active": {
                        backgroundColor: "var(--bs-primary)",
                        color: "white"
                    }
                }),
                placeholder: (provided, state) => ({
                    ...provided,
                    color: "white"
                }),
                singleValue: (provided, state) => ({
                    ...provided,
                    color: "white"
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    color: "inherit"
                })
            }} onChange={
                (e) => {
                    if (e != null) {
                        props.onSelection(e.value);
                    }
                }
            } key={`react_select_matching_dropdown_${Store.getState().program_qualifications[0].length}`} // key to force re-render
        />
    );
}


// component in the matching tab to match courses of the student to prerequisites
const Matching = (props) => {
    
    // use here to prevent creating hooks in loop in render function
    const allocatedQualifications = Store(state => state.program_qualifications[0]);

    return (

        <>

            <Accordion defaultActiveKey={"prerequisite-0"} alwaysOpen>
                {
                    // loop through all fields of the prerequisits
                    props.application_program.fields.map((field, index) => {

                        return (

                            <Accordion.Item eventKey={"prerequisite-" + index} key={"prerequisite-" + props.application_program.id + "-" + index}>
                                <Accordion.Header>
                                    <Col className="prerequisite-course-category-title">
                                        {field.name}
                                    </Col>
                                </Accordion.Header>
                                <Accordion.Body className="prerequisite-body">
                                    {/* eslint-disable-next-line */}
                                    { field.description != "" &&
                                    
                                        // course category description, if available
                                        <Row className="field-description">
                                            <Col className="col" xs="auto">
                                                <BsInfoCircle />
                                            </Col>
                                            <Col>
                                                {field.description}
                                            </Col>
                                        </Row>

                                    }

                                    {

                                        // loop through all courses of the prerequisite field
                                        field.courses.map((prerequisiteCourse, index) => {

                                            return (

                                                <Row className="prerequisite-courses" key={"prerequisite-" + props.application_program.id + "-" + prerequisiteCourse.id + "-" + index}>
                                                    <Col key={index} className="prerequisite col" xs="12" sm="6" md="6" lg="4" xl="4" xxl="4">
                                                        
                                                        {/* the on the left shown prerequisite course */}
                                                        <PrerequisiteCourse application_program={props.application_program} field={field} prerequisite_course={prerequisiteCourse} key={index} />
                                                    </Col>
                                                    <Col className="col" xs="12" sm="6" md="6" lg="8" xl="8" xxl="8">
                                                        {
                                                            allocatedQualifications
                                                                .filter(q => q.target === prerequisiteCourse.id && q.application_program === props.application_program.id)
                                                                .map((qualification, index) => {
                                                                    return <MatchAllocationCard 
                                                                        application_program={props.application_program} 
                                                                        match={qualification} 
                                                                        key={"prerequisite-" + props.application_program.id + "-" + prerequisiteCourse.id + "-" + qualification.id + "-" + index} />
                                                                })
                                                        }
                                                        <Col className="student-match-col" xs="12" sm="12" md="12" lg="6" xl="6" xxl="6">
                                                            <Row className="add-course-row">
                                                                <Col>
                                                                    <MatchingDropdown 
                                                                        prerequisite_course={prerequisiteCourse} 
                                                                        application_program={props.application_program}
                                                                        onSelection={(value) => { 
                                                                            // add the matching to the store: 0 -> indicates that we add it to the student
                                                                            Store.getState().addMatching(props.application_program.id, value, prerequisiteCourse.id, 0); 
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Col>
                                                </Row>

                                            );

                                        })

                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                        );

                    })
                }
            </Accordion>
        </>
    );

}

export default Matching;