import { useState } from 'react';
import { Accordion, Badge, Col, Form, Row } from 'react-bootstrap';

import Store from '@sharedModel/Store';
import { validateNumber, validateExtendedAlphaString } from '@sharedMiscellaneous/validationFunctions';


// lambda to validate the last name
const validateLastName = (name) => {
    return name.length >= 1 && validateExtendedAlphaString(name);
}

// lambda to validate the first name
const validateFirstName = (name) => {
    return name.length >= 1 && validateExtendedAlphaString(name);
}

// lambda to validate the application number
const validateApplicationNumber = (number) => {
    return number.length > 3 && validateNumber(number);
}


// component to edit the personal information of the student
const Student = () => {

    // local state variables storing the validity of the input fields
    const [isLastNameInvalid, setIsLastNameInvalid] = useState(false);
    const [isFirstNameInvalid, setIsFirstNameInvalid] = useState(false);
    const [isApplicationNumberInvalid, setIsApplicationNumberInvalid] = useState(false);
    
    // get all personal information from store
    
    const first_name = Store(state => state.personal_information.first_name);
    const last_name = Store(state => state.personal_information.last_name);
    const application_number = Store(state => state.personal_information.application_number);
    
    // helper function to count the number of valid fields - to show in badge
    const getNumberOfValidFields = () => {
        let validCount = 0;
        if (!isLastNameInvalid && last_name) validCount++;
        if (!isFirstNameInvalid && first_name) validCount++;
        if (!isApplicationNumberInvalid && application_number) validCount++;
        return validCount;
    };


    return (
        <Row className="student">
            <Accordion defaultActiveKey="student-0">
                <Accordion.Item eventKey="student-0">
                    <Accordion.Header>
                        <Col className="student-details-title">
                            Your Details<Badge bg="secondary">{getNumberOfValidFields()}/3</Badge>
                        </Col>
                    </Accordion.Header>
                    <Accordion.Body>
                        Please fill in your details below:
                        <Form className="student">

                            <Form.Group>
                                <Row>
                                    <Form.Label className="mandatory">First name(s)</Form.Label>
                                    <Form.Control type="text" 
                                        required 
                                        onChange={(e) => {
                                            Store.getState().setPersonalInformationProperty("first_name", e.target.value)
                                            setIsFirstNameInvalid(!validateFirstName(e.target.value))
                                        }}
                                        isInvalid={ isFirstNameInvalid }
                                        value={ first_name }
                                    />
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Form.Label className="mandatory">Last name(s)</Form.Label>
                                    <Form.Control type="text" 
                                        required 
                                        onChange={ (e) => {
                                            Store.getState().setPersonalInformationProperty("last_name", e.target.value)
                                            setIsLastNameInvalid(!validateLastName(e.target.value))
                                        }} 
                                        isInvalid={ isLastNameInvalid }
                                        value={ last_name }
                                    />
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Form.Label>Application number (optional)</Form.Label>
                                    <Form.Control type="text"  
                                        onChange={(e) => {
                                            Store.getState().setPersonalInformationProperty("application_number", e.target.value)
                                            setIsApplicationNumberInvalid(!validateApplicationNumber(e.target.value))
                                        }}
                                        isInvalid={ isApplicationNumberInvalid }
                                        value={ application_number }
                                    />
                                    <Form.Text className="text-muted">
                                        If available, you will get the application number during the online application.
                                    </Form.Text>
                                </Row>
                            </Form.Group>
                            
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Row>
    );

}

export default Student;