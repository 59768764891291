import React from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from '@sharedMiscellaneous/reportWebVitals';
import frontEndHelpers from '@sharedMiscellaneous/frontEndHelpers';

import 'bootstrap/dist/css/bootstrap.min.css'

import App from '@app/App';


const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/:application_program_id" element={<App />} />
        <Route path="/" element={<Navigate to="/mec" />} />
        <Route path="" element={<Navigate to="/mec" />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
frontEndHelpers();
