import Store from '@sharedModel/Store';

// get internal from external credits
export const externalToInternalCredits = (totalExternalCredits, totalExternalDuration, externalCredits, totalInternalDuration = 1.0, totalInternalCredits = 30.0) => {
    totalExternalCredits = parseFloat(totalExternalCredits);
    totalExternalDuration = parseFloat(totalExternalDuration);
    externalCredits = parseFloat(externalCredits);
    totalInternalDuration = parseFloat(totalInternalDuration);
    totalInternalCredits = parseFloat(totalInternalCredits);

    let oneExternalCredit = (totalInternalCredits / totalInternalDuration) / (totalExternalCredits / totalExternalDuration);
    
    return externalCredits * oneExternalCredit;
}

// derive the total internal credits for one application program and one prerequisite course for the k = -1 (current) revision
export const totalInternalCreditsFunc = (applicationProgramId, prerequisiteCourseId, k = -1) => {

    return Store(state => state.program_qualifications.at(k).reduce(
        (accumulator, qualification) => {
            if (qualification.target !== prerequisiteCourseId || qualification.application_program !== applicationProgramId) { 
                return accumulator;
            }

            // get degree for getting the total external credits
            const degree = state.degrees.find((d) => d.id === qualification.degree);
            // convert external credits to internal credits
            const internalCredits = externalToInternalCredits(degree.program.credits_nominal, degree.program.duration_nominal, qualification.credits);

            accumulator += internalCredits;

            return accumulator;
        }, 0));

}