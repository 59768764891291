
import { ReactSelectBootstrap } from 'react-select-bootstrap';

import { ApplicationPrograms } from "@sharedApplicationPrograms/ApplicationPrograms";


const ApplicationProgramSelection = (props) => {

    const options = [];
    ApplicationPrograms.list.forEach((program) => {
        console.log(program)
        options.push({ value: program.id, label: program.name });
    });


    return (
        <>
            <ReactSelectBootstrap
                className="react-select"
                options={options}
                value={{ value: props.application_program.id, label: props.application_program.name }}
                onChange={ props.onChangeApplicationProgram }
            />
        </>
    );
    
}

export default ApplicationProgramSelection;
