import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import BlobStream from 'blob-stream';
import FileSaver from 'file-saver';

import { attachStoreJsonToPdf } from '@sharedModel/Export'

// Register fonts with pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const exportPDF = (generatePdfTemplate, store, program, compare = false) => {
    const docDefinition = generatePdfTemplate(store, program, compare);

    // Create a PDF document
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    const pdfKitDoc = pdfDocGenerator.getStream();

    attachStoreJsonToPdf(store, pdfKitDoc);
    return pdfKitDoc;
};

export const downloadPDF = (pdfKitDoc, filename = 'report.pdf') => {
    const stream = pdfKitDoc.pipe(BlobStream());
    pdfKitDoc.end();

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf');
        FileSaver.saveAs(blob, filename);
    });
};