import { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';

import Degree from '@sharedComponents/Degree';
import Store from '@sharedModel/Store';
import DegreeDetailsModal from '@sharedComponents/DegreeDetailsModal';
import * as Model from '@sharedModel/Types';


// component returning all degrees as accordions
const Degrees = () => {	

    // get all degrees from store
    const degrees = Store(state => state.degrees);

    // modal for editing degree information: institute, college, ...
    const [showNewDegreeModal, setShowNewDegreeModal] = useState(false);
    const [newDegree, setNewDegree] = useState(new Model.Degree());

    const handleOpenNewDegreeModal = () => setShowNewDegreeModal(true);
    const handleCloseNewDegreeModal = () => setShowNewDegreeModal(false);

    const handleNewDegree = () => {
        setNewDegree(new Model.Degree());
        handleOpenNewDegreeModal();
    }


    return (
        <>
            <Accordion defaultActiveKey={"degree-0"} alwaysOpen>
                {
                    degrees.map((degree, index) => {
                        return (
                            <Degree degree={degree} key={index} item={index} />
                        );
                    })
                }
            </Accordion>
            <DegreeDetailsModal degree={newDegree} show={showNewDegreeModal} handleClose={handleCloseNewDegreeModal} createNew={true} />
            <Button variant="outline-primary" className="add-degree"
                onClick={handleNewDegree}>
                Add Degree
            </Button>
        </>
    );
    
}

export default Degrees;