const frontEndHelpers = executeOnce => {
    // https://www.cssscript.com/automatic-dark-mode-bootstrap/
    ;(function() {
        const htmlElement = document.querySelector("html")
        if (htmlElement.getAttribute("data-bs-theme") === 'auto') {
            function updateTheme() {
                document.querySelector("html").setAttribute("data-bs-theme", window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light")
            }
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme)
            updateTheme()
        }
    })()

    // prevent ugly jumping when switching tabs, just terrific!
    var tabPaneSizes = [0, 0, 0];

    window.addEventListener("resize", updateTabContentSize);
    window.addEventListener("click", updateTabContentSize, true);

    function updateTabContentSize(event) {
        document.querySelectorAll("div.tab-pane").forEach((tabPane, index) => {
            if (tabPane.scrollHeight > 0) {
                tabPaneSizes[index] = tabPane.scrollHeight;
            }
            document.querySelector("div.tab-content").style.minHeight = `${Math.max(...tabPaneSizes)}px`;
            // TODO account for content size extending view at the bottom and possible scroll position jumps on sudden content height decrease
        });
    }
};

export default frontEndHelpers;
