import { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

import Store from '@sharedModel/Store';
import { exportPDF, downloadPDF } from '@sharedModel/ExportBrowserImpl';
import { generatePdfTemplate } from '@sharedModel/Export';

import array from 'lodash';


const MAXIMUM_PERCENTAGE_OF_ALLOCATED_PREREQUISITES_FOR_WARNING = 0.8;


// export
const Export = (props) => {

    // eslint-disable-next-line
    const store_state = Store(state => state);
    const [comment, setComment] = useState(Store.getState().comment[0]);

    const onChangeComment = (e) => {
        setComment(e.target.value);
        Store.getState().setComment(e.target.value, 0);
    };

    const prerequisiteCourseIds = array.flatten(
        props.application_program.fields
            .map(field => field.courses.map(course => course.id))
    );
    const numberOfPrerequisites = prerequisiteCourseIds.length;
    const numberOfAllocatedPrerequisites = prerequisiteCourseIds
        .map(id => Store.getState().getAllocatedQualificationsByPrereqCourse(props.application_program.id, id).length !== 0)
        .reduce((acc, val) => Number(acc) + Number(val));
    const warningOnLessData = (numberOfAllocatedPrerequisites / numberOfPrerequisites) <= MAXIMUM_PERCENTAGE_OF_ALLOCATED_PREREQUISITES_FOR_WARNING;

    // eslint-disable-next-line
    let log_state = () => {
        console.log(store_state);
    };

    const savePdf = () => {
        const pdf_doc = exportPDF(generatePdfTemplate, Store, props.application_program, false);

        const pdf_filename = `TUHH_${props.application_program.id.toUpperCase()}_Application_${Store.getState().id}.pdf`;
        downloadPDF(pdf_doc, pdf_filename);
    };

    return (
        <>

            <Form>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form.Group>
                            <Form.Label>Your comment for us</Form.Label>
                            <Form.Control as="textarea" rows={3} className="export-comment" value={comment} onChange={onChangeComment} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group>
                            <Form.Label>
                                On this page you can export and download your entered information in the form of a PDF file. Before doing so, please check that all your information is correct and complete. You may also enter an optional comment for us, if you like. Click the button below once you are finished.
                            </Form.Label>
                            {warningOnLessData &&
                                <Form.Text className="text-danger">
                                    <p className="text-danger">You only allocated courses to {numberOfAllocatedPrerequisites} out of {numberOfPrerequisites} prerequisite subjects. Are you sure you want to export the application?</p>
                                </Form.Text>
                            }
                            <Button variant="primary" className="export-button" onClick={() => { savePdf(); }}>Export as personal PDF</Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            {/* <Button variant="outline-primary"
                onClick={() => { log_state() }}>
                Log Store State
            </Button> */}

        </>

    );

}

export default Export;