import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { BsFillPenFill, BsFillTrash3Fill } from 'react-icons/bs';

import Store from '@sharedModel/Store';
import CourseDetailsModal from '@sharedComponents/CourseDetailsModal';


// component representing a course of the student as a card in the edit mode (adding new courses to a degree)
const Course = (props) => {

    // const course = Store(state => state.courses.find((c) => c.id === props.courseId));

    // modal for editing course information
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const handleOpenDetailsModal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowDetailsModal(true);
    };

    const handleCloseDetailsModal = () => setShowDetailsModal(false);

    const handleDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
        // delete course from store
        Store.getState().removeCourse(props.course.id);
    };

    return (
        <>
            {/* modal for editing the details */}
            <CourseDetailsModal course={props.course} show={showDetailsModal} handleClose={handleCloseDetailsModal} createNew={false} />

            <Col className="course-col">
                <Card className="course">
                    <Card.Header>
                        <Row>
                            <Col className="course-title">
                                {props.course.subject}
                            </Col>
                            <Col className="col-auto">
                                <Button as="div" variant="outline-secondary" size="sm" className="ms-2" onClick={handleOpenDetailsModal}><BsFillPenFill /></Button>
                                <Button as="div" variant="outline-secondary" size="sm" className="ms-2" onClick={handleDelete}><BsFillTrash3Fill /></Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text className="course-code">{props.course.course_code}</Card.Text>
                        <Card.Text className="course-comment">{props.course.comment}</Card.Text>
                        <Row>
                            <Col>
                                <Card.Text>Credit points: {props.course.credits}</Card.Text>
                            </Col>
                            <Col>
                                <Card.Text>Compulsory: {props.course.compulsory ? "Yes" : "No"}</Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>

        </>
    );

}

export default Course;