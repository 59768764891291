import { Buffer } from "buffer";

import { externalToInternalCredits } from "@sharedModel/CreditPointCalc";

const attachmentName = "application_export.json";
const attachmentIsHidden = false;
export const cpPrintPrecision = 2;

// reporting helper
export const getAllocatedCpByProgramCourse = (store, application_program_id, program_course_id, k = -1) => {
	return store
		.getState()
		.program_qualifications.at(k).filter((qualification) => {
			return (
				qualification.application_program === application_program_id &&
				qualification.target === program_course_id
			);
		})
		.map((qualification) => {
			const course = store
				.getState()
				.courses.find((c) => c.id === qualification.course);
			const degree = store
				.getState()
				.degrees.find((d) => d.id === course.degree);

			return externalToInternalCredits(
				degree.program.credits_nominal,
				degree.program.duration_nominal,
				qualification.credits
			);
		})
		.reduce((acc, val) => {
			return acc + val;
		}, 0);
};

// reporting helper
export const getAllocatedCpByProgramField = (store, application_program_id, program_field_struct, k = -1) => {
	return program_field_struct.courses
		.map((program_course) => {
			return getAllocatedCpByProgramCourse(store, application_program_id, program_course.id, k);
		})
		.reduce((acc, val) => {
			return acc + val;
		}, 0);
};

export const attachStoreJsonToPdf = (store, pdfKitDoc) => {
	/* return */
	pdfKitDoc.file(Buffer.from(store.getState().exportJSON()), {
		name: attachmentName,
		hidden: attachmentIsHidden,
	});
};

export const generatePdfTemplate = (store, program, compare = false, font = "Roboto") => {

	// TODO: which auto columns to mark as 'noWrap: true'
	const docDefinition = {
		pageSize: "A4",
		pageOrientation: "portrait",
		pageMargins: [40, 60, 40, 60],
		styles: {
			header: {
				fontSize: 15,
				bold: true,
			},
			subheader: {
				fontSize: 12,
				bold: true,
			},
			subsubheader: {
				fontSize: 11,
				bold: true,
				italics: true,
			},
			quote: {
				italics: true,
			},
			small: {
				fontSize: 8,
			},
			table: {
				margin: [0, 5, 0, 15],
			},
			tableHeader: {
				bold: true,
				fontSize: 11,
			},
			headerfooter: {
				fontSize: 8,
				color: "grey",
			},
		},
		defaultStyle: {
			color: "black",
			font: font,
			fontSize: 10,
		},
		// header
		header: {
			margin: [20, 20, 20, 0],
			columns: [
				{
					width: "*",
					style: "headerfooter",
					text: `${program.id.toUpperCase()} ${
						store.getState().personal_information.application_number
					}`,
				},
				{
					width: "auto",
					style: "headerfooter",
					text: new Date().toISOString(),
				},
			],
		},
		// footer
		footer: (currentPage, pageCount) => {
			return {
				margin: [20, 35, 20, 0],
				columns: [
					{
						width: "*",
						style: "headerfooter",
						text: store.getState().id,
					},
					{
						width: "*",
						style: "headerfooter",
						text: `${process.env.REACT_APP_NAME} v${
							process.env.REACT_APP_VERSION
						}${
							process.env.REACT_APP_BUILD
								? "+" + process.env.REACT_APP_BUILD
								: ""
						}`,
					},
					{
						width: "auto",
						style: "headerfooter",
						text: `${currentPage}/${pageCount}`,
					},
				],
			};
		},

		// document contents
		content: [
			// First Page Header
			{ text: "TUHH Masters Application", style: "header" },
			{ text: program.name, italics: true, margin: [0, 0, 0, 20] },

			// Personal Information
			{ text: "Personal Information", style: "subheader" },
			{
				style: "table",
				table: {
					headerRows: 1,
					widths: ["*", "*", "*", "*"],
					body: [
						[
							{ style: "tableHeader", text: "First Name" },
							{ style: "tableHeader", text: "Last Name" },
							{ style: "tableHeader", text: "Application No." },
							{ style: "tableHeader", text: "Program" },
						],
						[
							store.getState().personal_information.first_name,
							store.getState().personal_information.last_name,
							store.getState().personal_information.application_number,
							program.name,
						],
					],
				},
			},

			// comments overall application
			{ text: "Comment", style: "subheader" },
			{ text: store.getState().comment[0] },

			// Degrees with Courses
			{ text: "Degrees", style: "subheader", pageBreak: "before" },
			...store.getState().degrees.map((degree, index) => {
				return [
					{
						text: `${index} - ${degree.program.subject}, ${degree.program.degree}`,
						style: "subsubheader",
					},
					{
						style: "table",
						table: {
							headerRows: 0,
							widths: ["auto", "*"],
							body: [
								// granting institution
								[
									"University",
									degree.granting_institution.name,
								],
								[
									"College",
									degree.granting_institution.college,
								],
								[
									"Country",
									`${degree.granting_institution.country.label} [${degree.granting_institution.country.value}]`,
								],

								// program
								["Degree", degree.program.degree],
								["Subject", degree.program.subject],
								[
									"Specialization",
									degree.program.specialization,
								],
								[
									"Duration Nominal",
									degree.program.duration_nominal,
								],
								[
									"Credits Nominal",
									degree.program.credits_nominal,
								],
								[
									"Credits System",
									degree.program.credit_system,
								],

								// final report
								[
									"Studies Start",
									`${
										parseInt(
											degree.report.date_start.month
										) + 1
									}/${degree.report.date_start.year}`,
								],
								[
									"Studies End",
									`${
										parseInt(degree.report.date_end.month) +
										1
									}/${degree.report.date_end.year}`,
								],
								[
									"Completed",
									degree.report.completed ? "yes" : "no",
								],
								[
									"Credits",
									`${degree.report.credits} ${degree.program.credit_system}`,
								],
							],
						},
					},

					// courses
					{
						style: "table",
						table: {
							headerRows: 1,
							widths: ["auto", "auto", "*", "auto"],
							body: [
								[
									{ style: "tableHeader", text: "Code" },
									{
										style: "tableHeader",
										text: "Module/Lecture",
									},
									{ style: "tableHeader", text: "Comment" },
									{
										style: "tableHeader",
										text: degree.program.credit_system,
									},
								],
								...store
									.getState()
									.courses.filter(
										(c) => c.degree === degree.id
									)
									.map((course) => {
										return [
											course.course_code || "-",
											{
												text: `${course.subject}${
													course.compulsory ? "*" : ""
												}`,
												bold: course.compulsory,
											},
											{
												text: course.comment,
												italics: true,
											},
											{
												text: course.credits,
												alignment: "right",
											},
										];
									}),
							],
						},
					},
				];
			}),

			// matches / allocations
			{ text: "Prerequisites", style: "subheader", pageBreak: "before" },
			{
				style: "table",
				table: {
					headerRows: 1,
					widths: ["*", "auto", "auto", "auto", ...(compare ? ["auto", "auto"] : [])],
					body: [
						[
							{ style: "tableHeader", text: "Requirement" },
							{ style: "tableHeader", text: "ECTS\nRequired" },
							{ style: "tableHeader", text: "ECTS\nAllocated" },
							{ style: "tableHeader", text: "CP\nAllocated" },
							// insert commission comparison data
							...(compare ? [
									{ style: "tableHeader", text: "ECTS\nGranted" },
									{ style: "tableHeader", text: "CP\nGranted" },
								] : []
							)
						],
						// show matches with CP unit
						...program.fields
							.map((program_field) => {
								// calculate required cp for field
								const required_cp_field = program_field.courses
									.map((c) => parseFloat(c.required_cp))
									.reduce((acc, val) => {
										return acc + val;
									}, 0);

								// allocated cp for field
								const allocated_cp_program_field_student =
									getAllocatedCpByProgramField(store, program.id, program_field, 0);
								const allocated_cp_program_field_commission =
									getAllocatedCpByProgramField(store, program.id, program_field, -1);

								// build table with field courses and allocated students courses
								return [
									// field name, bold, italics
									[
										{
											text: program_field.name,
											bold: true,
											italics: true,
										},
										{
											text: required_cp_field,
											bold: true,
											italics: true,
											alignment: "right",
										},
										{
											text: allocated_cp_program_field_student.toFixed(
												cpPrintPrecision
											),
											bold: true,
											italics: true,
											alignment: "right",
										},
										{ text: "", bold: true, italics: true },
										// pad for commission comparison
										...(compare ? [
												{
													text: allocated_cp_program_field_commission.toFixed(
														cpPrintPrecision
													),
													bold: true,
													italics: true,
													alignment: "right",
												},
												{ text: "", bold: true, italics: true },
											] : []
										)
									],
									...program_field.courses
										.map((program_course) => {
											// calculate allocated cp for required course
											const allocated_cp_program_course_student =
												getAllocatedCpByProgramCourse(
													store,
													program.id,
													program_course.id,
													0
												);
											const allocated_cp_program_course_commission =
												getAllocatedCpByProgramCourse(
													store,
													program.id,
													program_course.id,
													-1
												);

											// build table of allocated courses
											return [
												// prerequisite course name, bold
												[
													{
														text: program_course.subject,
														bold: true,
													},
													{
														text: program_course.required_cp,
														bold: true,
														alignment: "right",
													},
													{
														text: allocated_cp_program_course_student.toFixed(
															cpPrintPrecision
														),
														bold: true,
														alignment: "right",
													},
													{ text: "", bold: true },
													// pad for commission comparison
													...(compare ? [
															{
																text: allocated_cp_program_course_commission.toFixed(
																	cpPrintPrecision
																),
																bold: true,
																alignment: "right",
															},
															{ text: "", bold: true },
														] : []
													)
												],
												...[
													// get allocations from first (student) and last (latest commission)
													...store
														.getState()
														.program_qualifications.at(0).filter(
															(qualification) => {
																return (
																	qualification.application_program ===
																		program.id &&
																	qualification.target ===
																		program_course.id
																);
															}
														),
													...store
														.getState()
														.program_qualifications.at(-1).filter(
															(qualification) => {
																return (
																	qualification.application_program ===
																		program.id &&
																	qualification.target ===
																		program_course.id
																);
															}
														)
													]
													// dedupe allocations by degree and course
													.reduce((acc, val) => {
														// set credits to 0 on return
														return acc.find((e) => {
															return (
																e.degree === val.degree &&
																e.course === val.course
															);
														}) ? acc : [...acc, Object.assign({}, val, {credits: 0})];
													}, [])
													.map((qualification_display) => {
														const course = store
															.getState()
															.courses.find(
																(c) =>
																	c.id ===
																	qualification_display.course
															);

														const degree = store
															.getState()
															.degrees.find(
																(d) =>
																	d.id ===
																	course.degree
															);
														
														
														// lookup allocated credits for student and commission
														// lookup student
														const qualification_student = store.getState().program_qualifications.at(0).find((q) => {
															return (
																q.application_program === qualification_display.application_program &&
																q.target === qualification_display.target &&
																q.degree === qualification_display.degree &&
																q.course === qualification_display.course
															);
														});

														const cp_credit_student = qualification_student ? qualification_student.credits : 0;
														const cp_ects_student =
															externalToInternalCredits(
																degree.program
																	.credits_nominal,
																degree.program
																	.duration_nominal,
																cp_credit_student
															);

														// lookup commission
														const qualification_commission = store.getState().program_qualifications.at(-1).find((q) => {
															return (
																q.application_program === qualification_display.application_program &&
																q.target === qualification_display.target &&
																q.degree === qualification_display.degree &&
																q.course === qualification_display.course
															);
														});

														const cp_credit_commission = qualification_commission ? qualification_commission.credits : 0;
														const cp_ects_commission =
															externalToInternalCredits(
																degree.program
																	.credits_nominal,
																degree.program
																	.duration_nominal,
																cp_credit_commission
															);

														// allocated course row
														return [
															{
																text: course.subject,
																italics: true,
															},
															{
																text: "",
																italics: true,
															},
															{
																text: cp_ects_student.toFixed(
																	cpPrintPrecision
																),
																italics: true,
																alignment:
																	"right",
															},
															{
																columns: [
																	{
																		width: "*",
																		text: degree
																			.program
																			.credit_system,
																		italics: true,
																	},
																	{
																		width: "auto",
																		text: cp_credit_student,
																		italics: true,
																		margin: [
																			5,
																			0,
																			0,
																			0,
																		],
																	},
																],
															},
															// insert commission comparison data
															...(compare ? [
																	{
																		text: cp_ects_commission.toFixed(
																			cpPrintPrecision
																		),
																		italics: true,
																		alignment:
																			"right",
																	},
																	{
																		columns: [
																			{
																				width: "*",
																				text: degree
																					.program
																					.credit_system,
																				italics: true,
																			},
																			{
																				width: "auto",
																				text: cp_credit_commission,
																				italics: true,
																				margin: [
																					5,
																					0,
																					0,
																					0,
																				],
																			},
																		],
																	},
																] : []
															)
														];
													}),
											];
										})
										.flat(),
								];
							})
							.flat(),
					],
				},
			},

			// unallocated
			{ text: "Unallocated", style: "subheader", pageBreak: "before" },
			{
				style: "table",
				table: {
					headerRows: 1,
					widths: ["*", "auto", "auto"],
					body: [
						[
							{ style: "tableHeader", text: "Module/Lecture" },
							{ style: "tableHeader", text: "ECTS" },
							{ style: "tableHeader", text: "CP" },
						],
						...store
							.getState()
							.courses.filter(
								(course) =>
									store
										.getState()
										.getTotalAllocatedCreditsByCourseId(
											program.id,
											course.id
										) !== course.credits
							)
							.map((course) => {
								const degree = store
									.getState()
									.degrees.find(
										(d) => d.id === course.degree
									);
								const unallocated_cp =
									course.credits -
									store
										.getState()
										.getTotalAllocatedCreditsByCourseId(
											program.id,
											course.id
										);
								const cp = externalToInternalCredits(
									degree.program.credits_nominal,
									degree.program.duration_nominal,
									unallocated_cp
								);

								return [
									{ text: course.subject, italics: true },
									{
										text: cp.toFixed(cpPrintPrecision),
										italics: true,
										alignment: "right",
									},
									{
										columns: [
											{
												width: "*",
												text: degree.program
													.credit_system,
												italics: true,
											},
											{
												width: "auto",
												text: unallocated_cp,
												italics: true,
												margin: [5, 0, 0, 0],
											},
										],
									},
								];
							}),
					],
				},
			},
		],
	};

	return docDefinition;
};