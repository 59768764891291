import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Tab, Nav } from "react-bootstrap";

import Store from '@sharedModel/Store';

import { ApplicationPrograms } from "@sharedApplicationPrograms/ApplicationPrograms";
import ApplicationProgramSelection from "@components/ApplicationProgramSelection";
import Header from "@components/Header";
import Footer from "@sharedComponents/Footer";
import Student from "@components/Student";
import Degrees from "@sharedComponents/Degrees";
import Matching from "@components/Matching";
import Export from "@components/Export";

import "./App.css";


const App = (props) => {

    const { application_program_id } = useParams();

    const navigate = useNavigate();
    const [ selectedApplicationProgram, setSelectedApplicationProgram ] = useState(ApplicationPrograms.getValidProgramById(application_program_id));

    const onChangeApplicationProgram = (e) => {

        setSelectedApplicationProgram(ApplicationPrograms.getValidProgramById(e.value));
        navigate("../" + ApplicationPrograms.getValidProgramById(e.value).id);

    }

    const startOver = () => {
        Store.getState().resetStore();
    };

    return (
        <Container>

            {/* header information */}
            <Header application_program_name={selectedApplicationProgram.name} />

            {/* selection of application program information */}
            <ApplicationProgramSelection application_program={selectedApplicationProgram} onChangeApplicationProgram={onChangeApplicationProgram} />

            {/* start over button */}
            <Row className="start-over align-items-center">
                <Col className="col" xs="12" sm="6">
                    Hint: In case you wish to start over, click here at any time:
                </Col>
                <Col className="col" xs="12" sm="6">
                    <Button variant="outline-primary" className="add-course" onClick={startOver}>Start Over</Button>
                </Col>
            </Row>
            
            {/* student information */}
            <Student />

            {/* tabs for credits, courses, and export including generating the pdf */}
            <Row>

                <Tab.Container defaultActiveKey="prerequisites">
                    
                    {/* navigation bar */}
                    <Nav as="ul" variant="pills" className="nav-fill nav-justified justify-content-center flex-column flex-sm-row">
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="prerequisites" className="prerequisites">Our Prerequisites</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="credits" className="credits">Your Modules/Lectures</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="export" className="export">Export</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    
                    {/* the tabs */}
                    <Tab.Content className="process-tabs">
                        <Tab.Pane eventKey="prerequisites">

                            {/* matching the prerequisites and the student courses */}
                            <Matching application_program={selectedApplicationProgram} />

                        </Tab.Pane>
                        <Tab.Pane eventKey="credits">

                            {/* component to add the degrees and courses */}
                            <Degrees />

                        </Tab.Pane>
                        <Tab.Pane eventKey="export">

                            <Export application_program={selectedApplicationProgram} />

                        </Tab.Pane>
                    </Tab.Content>

                </Tab.Container>

            </Row>

            <Footer name={ process.env.REACT_APP_NAME } version={ process.env.REACT_APP_VERSION } build={ process.env.REACT_APP_BUILD } />
        </Container>
    );

}


export default App;
